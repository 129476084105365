function randomFromInterval (from, to) {
    return Math.floor(Math.random() * (to - from + 1) + from);
}
var characters = [
    {
        msgType: 'src="assets/img/character-specific/zebra-msgtypes.png" data-interchange="[assets/img/character-specific/zebra-msgtypes@2.png, retina]"',
        timed: 'src="assets/img/character-specific/zebra-timed.png" data-interchange="[assets/img/character-specific/zebra-timed@2.png, retina]"',
        character: 'src="assets/img/character-specific/zebra.png" data-interchange="[assets/img/character-specific/zebra@2.png, retina]"',
        backgroundRetina: "[assets/img/character-specific/zebra-background.png, small], [assets/img/character-specific/zebra-background@2.png, retina]",
        designotro: 'src="assets/img/character-specific/zebra-designotro.png" data-interchange="[assets/img/character-specific/zebra-designotro@2.png, retina]"'
    },
    {
        msgType: 'src="assets/img/character-specific/cow-msgtypes.png" data-interchange="[assets/img/character-specific/cow-msgtypes@2.png, retina]"',
        timed: 'src="assets/img/character-specific/cow-timed.png" data-interchange="[assets/img/character-specific/cow-timed@2.png, retina]"',
        character: 'src="assets/img/character-specific/cow.png" data-interchange="[assets/img/character-specific/cow@2.png, retina]"',
        backgroundRetina: "[assets/img/character-specific/cow-background.png, small], [assets/img/character-specific/cow-background@2.png, retina]",
        designotro: 'src="assets/img/character-specific/cow-designotro.png" data-interchange="[assets/img/character-specific/cow-designotro@2.png, retina]"'
    },
    {
        msgType: 'src="assets/img/character-specific/sheep-msgtypes.png" data-interchange="[assets/img/character-specific/sheep-msgtypes@2.png, retina]"',
        timed: 'src="assets/img/character-specific/sheep-timed.png" data-interchange="[assets/img/character-specific/sheep-timed@2.png, retina]"',
        character: 'src="assets/img/character-specific/sheep.png" data-interchange="[assets/img/character-specific/sheep@2.png, retina]"',
        backgroundRetina: "[assets/img/character-specific/sheep-background.png, small], [assets/img/character-specific/sheep-background@2.png, retina]",
        designotro: 'src="assets/img/character-specific/sheep-designotro.png" data-interchange="[assets/img/character-specific/sheep-designotro@2.png, retina]"'
    }
]; 

$(document).ready(function (){
    randomCharacter();
});
    
var randomCharacterNumber = function () {
    var r = randomFromInterval(0, characters.length);
    if (r == characters.length) {
        r = (r + 1) % characters.length;
    }
    return r;
};

var randomCharacter = function () {
    
    var ranNumber = randomCharacterNumber();
    var character = characters[ranNumber];
    
    var characterDiv = $('#character');
    if (characterDiv) {
        characterDiv.html('<img id="char"' + character.character + '/>');
        
    }
    var timedDiv = $('#timed');
    if (timedDiv) {
        timedDiv.html('<img ' + character.timed + ' class="icon-sized"/>');
        
    }
    var msgtypeDiv = $('#msgtype');
    if (msgtypeDiv) {
        msgtypeDiv.html('<img ' + character.msgType + ' class="icon-sized"/>');
        
    }
    var designotroDiv = $('#designotro');
    if (designotroDiv) {
        designotroDiv.html('<img ' + character.designotro + ' class="icon-sized"/>');
        
    }
    
    var footerBackgroundDiv = $('#footerBackground');
    if (typeof footerBackgroundDiv != "undefined") {
        footerBackgroundDiv.attr("data-interchange", character.backgroundRetina);
    }
    $(document).foundation();
};




